<template>
  <div class="photograph-footer">
    <PageFooter class="side-copy-right" />
    <div
      class="button-container"
      v-for="btn_contain in get_all_btn_containers"
      :key="btn_contain.url"
    >
      <div class="button-container-text">
        <span>{{ btn_contain.text }}</span>
      </div>
      <a class="button-container-button" :href="btn_contain.url"
        ><h3>{{ btn_contain.btn_text }}</h3></a
      >
    </div>
  </div>
  <PageFooter class="footer-copy-right" />
</template>

<script>
// @ is an alias to /src
import PageFooter from './PageFooter.vue';

export default {
  name: 'PhotographStory',
  components: { PageFooter },
  data() {
    return {
      button_containers: [
        // { text: 'I blog about business and technology.', url: '#', btn_text: 'Blog' },
        {
          text: 'Interested in working together (or to just having coffe?)',
          url: 'https://form.typeform.com/to/CGVA9ZdE',
          btn_text: 'Contact ',
        },
      ],
    };
  },
  computed: {
    get_all_btn_containers() {
      return this.button_containers;
    },
  },
};
</script>

<style scoped>
.photograph-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px 70px;
  column-gap: 50px;
  row-gap: 70px;
}
.button-container {
  margin-top: 50px;
}

.button-container-text {
  font-weight: 400;
  font-size: 32px;
  line-height: 34px;
  text-align: center;
  width: 350px;
  max-width: 80vw;
  min-height: 100px;
  margin-bottom: 20px;
}
.button-container-button {
  background-color: rgba(0, 0, 0, 0.9); /* Green */
  border: none;
  color: white;
  width: 275px;
  height: 72px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-content: center;
  font-weight: 700;
  font-size: 64px;
  line-height: 67px;
  text-align: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.button-container-button::before {
  position: absolute;
  content: '';
  width: 400px;
  height: 70%;
  background: #fff;

  animation: rotate 3s linear infinite;
}
.button-container-button::after {
  content: '';
  position: absolute;
  inset: 4px;
  background-color: #000;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.button-container-button h3 {
  position: relative;
  z-index: 10;
}
.side-copy-right {
  display: none;
}

@media screen and (min-width: 320px) {
  .side-copy-right {
    display: none;
  }
  .footer-copy-right {
    display: flex;
    margin: 40px 10px 20px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    row-gap: 20px;
    flex-wrap: wrap;
    align-items: flex-start;
    font-size: 24px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.button-container-button:hover {
  transform: translateY(3px);
}
@media screen and (min-width: 1400px) {
  .side-copy-right {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    row-gap: 15px;
  }
  .footer-copy-right {
    display: none;
  }
}
</style>
