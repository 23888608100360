<template>
  <div class="uppercase text-right m-5 absolute inset-x-0 sm:text-3xl md:text-7xl nav-header">
    <span>Ferdinand</span><span class="font-bold pl-2">Biere</span>
    <ol class="lowercase text-xl font-light pt-2 -space-y-1">
      <li
        v-for="link in links"
        :key="link.text"
        class="cursor-pointer hover:underline duration-200"
      >
       <a :href="link.href">{{ link.text }} -</a>
      </li>
    </ol>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'SideHeader',
  components: {},
  data() {
    return {
      links: [
        { text: 'biography', href: '#biography' },
        // { text: 'blog', href: '#' },
        { text: 'contact', href: 'https://form.typeform.com/to/CGVA9ZdE' },
      ],
    };
  },
};
</script>

<style scoped>
.nav-header {
  z-index: 200;
}
</style>
