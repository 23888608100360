<template>
  <modal class="h-screen w-screen fixed w-screen h-screen top-0 left-0 overflow-hidden
                bg-gray-900 bg-opacity-80 sticky-0"
         style="z-index:1000000;" v-if="isOpen">
    <div class="h-full w-full grid place-items-center">
      <div class="bg-background-accent w-full md:w-5/6 lg:w-1/3 h-fit px-5
                  flex-col justify-center items-center gap-4 py-8 flex">
        <h1 class="text-3xl text-white text-center pb-6">
          Privacy Settings</h1>
        <span class=" text-white">
             We are using 3rd party cookies and libaries in accordance to our
              <a href="/gdpr">privacy policy</a>.
        </span>
        <div class="flex flex-row justify-between">
          <router-link to="/gdpr" class="h-full">
            <button class="lowercase py-4 px-2 bg-secondary-dark border-1
                       text-white
                         mr-4 hover:bg-transparent hover:text-secondary-dark text-sm
                         border-transparent text-md hover:border-secondary-dark"
                    @click="isOpen = false">
                    More information
                    </button>
          </router-link>
          <button class="uppercase py-2 px-4 bg-primary-dark border-2 text-white text-md
                       mr-4 hover:bg-transparent hover:text-white hover:border-primary-dark
                       border-white" @click="enable()">
            Accept and continue</button>
        </div>
        <button class="text-gray-400 underline text-sm hover:text-white" @click="disable()">
          Continue without non-essential cookies</button>
      </div>
    </div>
  </modal>
</template>

<script>

export default {
  name: 'CookieBanner',
  components: {
  },
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    enable() {
      this.isOpen = false;
      this.$emit('enable');
    },
    disable() {
      this.isOpen = false;
      this.$emit('disable');
    },
  },
};
</script>

<style scoped>
.checkbox:checked+.check-icon {
  display: flex;
}
</style>
