<template>
<CookieBanner v-if="showCookieBanner" class="z-100" @disable="setConsentCookie('disabled')"
                @enable="enable()" />
  <div class="font-inconsolata main z-30">
    <div data-tf-popover="CGVA9ZdE" data-tf-custom-icon="" data-tf-button-color="#000000"
         data-tf-tooltip="Hey 👋&nbsp;&nbsp;Need something?" data-tf-chat data-tf-medium="snippet"
         style="all:unset;"></div>
    <router-view />
  </div>
</template>

<script>
import { useCookies } from 'vue3-cookies';
import CookieBanner from './components/CookieBanner.vue';

const cookieName = 'cookieConsentGranted';
const { cookies } = useCookies();
export default {
  name: 'App',
  components: {
    CookieBanner,
  },
  methods: {
    /* eslint-enable */
    enableTracking() {
      const typeformScript = document.createElement('script');
      typeformScript.setAttribute('src', '//embed.typeform.com/next/embed.js');
      document.body.appendChild(typeformScript);

      const googleAnalyticsScript = document.createElement('script');
      googleAnalyticsScript.setAttribute(
        'src',
        'https://www.googletagmanager.com/gtag/js?id=G-YNTNHBY2FZ',
      );
      googleAnalyticsScript.setAttribute('async', 'true');
      document.body.appendChild(googleAnalyticsScript);
      window.dataLayer = window.dataLayer || [];
      // eslint-disable-next-line prefer-rest-params
      function gtag() { window.dataLayer.push(arguments); }
      gtag('js', new Date());

      gtag('config', 'G-YNTNHBY2FZ');
    },
    setConsentCookie(val) {
      cookies.set(cookieName, val);
    },
    enable() {
      this.setConsentCookie('enabled');
      this.enableTracking();
    },
    source() {
      if (document.referrer.search('https?://(.*)google.([^/?]*)') === 0) {
        return 'Google';
      }
      if (document.referrer.search('https?://(.*)bing.([^/?]*)') === 0) {
        return 'Bing';
      }
      if (document.referrer.search('https?://(.*)yahoo.([^/?]*)') === 0) {
        return 'Yahoo';
      }
      if (document.referrer.search('https?://(.*)facebook.([^/?]*)') === 0) {
        return 'Facebook';
      }
      if (document.referrer.search('https?://(.*)twitter.([^/?]*)') === 0) {
        return 'Twitter';
      }
      if (document.referrer.search('https?://(.*)slack.([^/?]*)') === 0) {
        return 'Slack';
      }
      return 'Other';
    },
  },
  created() {
    // Check if consent cookie has been set and only show dialog if it hasn't.
    const cookie = cookies.get(cookieName);

    if (cookie === 'enabled') {
      this.showCookieBanner = false;
      this.cookiesAllowed = true;
      this.enableTracking();
    } else if (cookie === 'disabled') {
      this.showCookieBanner = false;
    } else {
      this.showCookieBanner = true;
    }
  },
  data: () => ({
    showCookieBanner: true,
    cookiesAllowed: false,
  }),
};
</script>

<style>
body::-webkit-scrollbar {
  display: none;
}
.main {
  scroll-behavior: smooth;
}
</style>
