<template>
  <div class="social-media-container" v-if="content !== null">
    <div class="social-media-container-icons">
      <div
        class="social-media-icon"
        v-for="icon in this.content.icons"
        :key="icon.icon"
      >
        <a :href="icon.href" target="_blank">
          <img :src="getIconSrc(icon.icon)" alt="icon.icon" />
        </a>
      </div>
    </div>
    <div class="social-media-container-text-lines">
      <div
        class="social-media-text-line"
        v-for="textLine in this.content.textLines"
        :key="textLine.text"
      >
        <a :href="textLine.href"
          ><span>{{ textLine.text }}</span></a
        >
      </div>
    </div>
    <div class="social-media-container-copyRight">
      <div
        class="social-media-copyRight-text"
        v-for="socialLink in this.content.disclaimer"
        :key="socialLink"
      >
        <span>{{ socialLink }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',
  data() {
    return {
      content: {
        icons: [
          {
            icon: 'linkIn',
            href: 'https://www.linkedin.com/in/ferdinandbiere/',
          },
          {
            icon: 'medium',
            href: 'https://medium.com/@fbiere',
          },
        ],
        textLines: [
          { text: 'imprint', href: '/imprint' },
          { text: 'gdpr', href: '/gdpr' },
          { text: 'press kit', href: 'https://drive.google.com/drive/folders/19nzUNxxZY-Dw3HdM2aj_lR5COS7z1NfF?usp=sharing' },
        ],
        disclaimer: [`© 2013 - ${new Date().getFullYear()}`,
          'Created by Ferdinand Biere'],
      },
    };
  },
  methods: {
    getIconSrc(iconPath) {
      const icon = require.context('../assets/social_medien_icons/', false, /\.svg$/);
      return icon(`./${iconPath}.svg`);
    },
  },
};
</script>

<style>
.social-media-container {
  margin: 50px 10px;
  font-size: 24px;
}

.social-media-container-icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: flex-start;
  column-gap: 30px;
  min-width: 150px;
}

.social-media-text-line span {
  text-decoration: underline;
}

.social-media-container-text-lines {
  order: -1;
  margin-right: 50px;
}

.social-media-container-icons {
  border-right: none;
  margin-right: 50px;
}

@media screen and (min-width: 733px) {
  .social-media-container-icons {
    border-right: 2px solid #000;
  }
}
@media screen and (min-width: 1400px) {
  .social-media-container-text-lines {
    order: 0;
    margin-right: 0px;
  }
  .social-media-container-icons {
    border-right: none;
    margin-right: 0px;
  }
}
</style>
