<template>
  <section id="biography">
    <div class="photograph-story">
      <span v-for="section in get_all_sections" :key="section">{{ section }}</span>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'PhotographStory',
  components: {},
  data() {
    return {
      sections: [
        'Ferdinand Biere is a software developer turned technology entrepreneur from Berlin, Germany. He started his first company at age 18 during his CS study at Berlin Institute of Technology.',
        'In 2020, he co-founded DeepMetis, an AI research and development company that regularly does projects with high-profile DAX companies and published a paper in Nature Journal together with a Max Planck institute.',
        'He is a founding member and president of the board at Quarano, an NGO that attracted national attention at the beginning of the Covid-19 pandemic by supporting health authorities with much-needed digitization. Their MVP was in real-world use within just three months and got recognition from multiple national and international institutions, including a nomination for the 2020 eco startup award.',

      ],
    };
  },
  computed: {
    get_all_sections() {
      return this.sections;
    },
  },
};
</script>

<style scoped>
.photograph-story {
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  line-height: 33px;
  font-size: 20px;
  padding: 24px;
}
.photograph-story span {
  hyphens: auto;
  text-align: justify;
}

@media screen and (min-width: 768px) {
  .photograph-story {
    padding: 72px 114px 53px 114px;
    font-size: 32px;
  }
}
</style>
