import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: () => import(/* webpackChunkName: "blog" */ '../views/ImprintView.vue'),
  },
  {
    path: '/gdpr',
    name: 'gdpr',
    component: () => import(/* webpackChunkName: "blog" */ '../views/GdprView.vue'),
  },
  // {
  //   path: '/blog',
  //   name: 'blog',
  //   component: () => import(/* webpackChunkName: "blog" */ '../views/BlogView.vue'),
  // },
  // {
  //   path: '/blog/:id',
  //   name: 'post',
  //   component: () => import(/* webpackChunkName: "post" */ '../views/BlogPostView.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line consistent-return
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
  },
});

export default router;
