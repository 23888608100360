<template>
  <div class="photograph-page">
    <div class="photograph-img">
      <img class="" src="@/assets/headshot.png" alt="Photograph of Ferdinand Biere" />
    </div>
    <div class="photograph-side-container">
      <div class="photograph-side-text">
        <p>
          Berlin-based<br /><span class="font-bold">Technology Consultant</span><br />and
          <span class="font-bold">Entrepreneur</span>
        </p>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhotographPage',
};
</script>

<style scoped>
.photograph-page {
  display: flex;
  flex-direction: row-reverse;
  justify-items: flex-start;
  position: relative;
  width: 100vw;
}

.photograph-img {
  height: 100vh;
  /* border: 2px solid orange;
  border-bottom: none;
  border-left: none;
  border-radius: 0 100% 0 0;
  background-color: orange;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100; */
}
.photograph-img img {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 50%;
  max-width: 100vw;
  object-fit: cover;
}

.photograph-side-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 170px;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  padding-bottom: 46px;
  padding-left: 25px;
}

.photograph-side-text {
  font-size: 30px;
}

@media screen and (min-width: 320px) {
  .photograph-side-container {
    justify-content: flex-start;
    padding-top: 170px;
    margin-left: auto;
    margin-right: auto;
  }
  .photograph-img img {
    height: 50%;
  }
  .photograph-side-text {
    font-size: 30px;
  }
}

@media screen and (min-width: 524px) {
  .photograph-side-container {
    justify-content: center;
    padding-top: 0px;
    padding-right: 20px;
    margin-right: 0;
  }
  .photograph-img img {
    height: 80%;
  }
  .photograph-side-container {
    max-width: 40vw;
  }
  .photograph-side-text {
    font-size: 32px;
  }
}
@media screen and (min-width: 768px) {
  .photograph-img img {
    height: 70%;
  }
  .photograph-side-container {
    max-width: 70vw;
  }
}
@media screen and (min-width: 992px) {
  .photograph-img img {
    height: 90%;
  }
  .photograph-side-container {
    justify-content: flex-end;
  }
  .photograph-img img {
    height: 100%;
  }
}
</style>
