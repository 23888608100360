<template>
  <SideHeader />
  <PhotographHeader><FeaturesIn class="side-features" /></PhotographHeader>
  <PhotographStory />
  <FeaturesIn class="line-features" />
  <PhotographFooter />
</template>

<script>
// @ is an alias to /src
import SideHeader from '../components/SideHeader.vue';
import PhotographHeader from '../components/PhotographHeader.vue';
import PhotographStory from '../components/PhotographStory.vue';
import PhotographFooter from '../components/PhotographFooter.vue';
import FeaturesIn from '../components/FeaturesIn.vue';

export default {
  name: 'HomeView',
  data() {
    return {};
  },
  components: {
    SideHeader,
    PhotographHeader,
    PhotographFooter,
    PhotographStory,
    FeaturesIn,
  },
  computed: {
    isMobile() {
      return false;
    },
  },
};
</script>

<style>
.side-features {
  display: none;
}

@media screen and (min-width: 320px) {
  .side-features {
    display: none;
  }
  .line-features {
    display: block;
    margin-top: 40px;
  }
}

@media screen and (min-width: 992px) {
  .side-features {
    display: block;
  }
  .line-features {
    display: none;
  }
}
</style>
