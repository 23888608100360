<template>
  <div class="features-in-container">
    <span class="features-in-title">featured in</span>
    <div class="featured-box">
      <div class="features-in primary">
        <div v-for="logo in featureLogos" :key="logo" class="features-in-items">
          <img :alt="logo" :src="getImgUrl(logo)" class="features-in-items-img" />
        </div>
      </div>
      <div class="features-in secondary">
        <div v-for="logo in featureLogos" :key="logo" class="features-in-items">
          <img :alt="logo" :src="getImgUrl(logo)" class="features-in-items-img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeaturesIn',
  components: {},
  data() {
    return {
      featureLogos: ['deutschlandfunk', 'tagesthemen', 'welt', 'zeit', 'manager_magazin'],
    };
  },
  methods: {
    getImgUrl(imagePath) {
      const images = require.context('../assets/features_logos/', false, /\.png$/);
      return images(`./${imagePath}.png`);
    },
  },
};
</script>

<style>
.features-in-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-top: 116px;
  overflow: hidden;
}

.features-in-title {
  font-size: 24px;
}
.featured-box {
  border: 2px solid #000000;
  padding: 10px;
  height: 80px;
  width: 98vw;
  position: relative;
  overflow: hidden;
}
.features-in {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  width: max-content;
  height: inherit;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  left: 0%;
}

.features-in::-webkit-scrollbar {
  display: none;
}

.features-in-items {
  display: inline-block;
  min-width: 300px;
  height: 100%;
  margin: auto 15px auto;
}

.features-in-items-img {
  height: 80%;
  width: auto;
  object-fit: scale-down;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.primary {
  animation: infinite-scroll 10s linear infinite;
  z-index: 2;
}

.secondary {
  animation: infinite-scroll-backward 10s linear infinite;
  z-index: 1;
}

@media screen and (min-width: 320px) {
  .features-in-container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .featured-box {
    width: 95vw;
  }
  @keyframes infinite-scroll {
    from {
      left: 0%;
    }
    to {
      left: -700%;
    }
  }
  @keyframes infinite-scroll-backward {
    from {
      left: 700%;
    }
    to {
      left: 0%;
    }
  }
}

@media screen and (min-width: 756px) {
  @keyframes infinite-scroll {
    from {
      left: 0%;
    }
    to {
      left: -300%;
    }
  }
  @keyframes infinite-scroll-backward {
    from {
      left: 300%;
    }
    to {
      left: 0%;
    }
  }
}

@media screen and (min-width: 992px) {
  .featured-box {
    width: 40vw;
  }
  @keyframes infinite-scroll {
    from {
      left: 0%;
    }
    to {
      left: -500%;
    }
  }
  @keyframes infinite-scroll-backward {
    from {
      left: 500%;
    }
    to {
      left: 0%;
    }
  }
}
@media screen and (min-width: 1200px) {
  @keyframes infinite-scroll {
    from {
      left: 0%;
    }
    to {
      left: -400%;
    }
  }
  @keyframes infinite-scroll-backward {
    from {
      left: 400%;
    }
    to {
      left: 0%;
    }
  }
}
</style>
